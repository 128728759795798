
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { ValidationError } from 'class-validator';

import UserService, { UserServiceS } from '@/modules/user/user.service';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';

import Dialog from '@/modules/common/components/ui-kit/dialog.vue';
import ValidationErrorComponent from '@/modules/common/components/ui-kit/validation-error.vue';
import EventGroup from '@/modules/events/interfaces/event-group.enum';

import ViewEventTitle from './view-event-title.vue';

@Component({
    components: {
        ValidationErrorComponent,
        Dialog,
        ViewEventTitle,
    },
    filters: {
        NumberOfGuestsFilter: (value: string | number | null) => {
            if (!value) {
                return '-';
            }
            if (Number(value) <= 0) {
                return '-';
            }
            return value;
        },
    },
})
export default class ViewEventForm extends Vue {
    @Inject(EventsManagerServiceS)
    private eventsManagerService!: EventsManagerService;

    @Inject(UserServiceS)
    private userService!: UserService;

    @Prop({ required: false })
    private eventId?: string;

    isDeleting: boolean = false;
    isIgnoring: boolean = false;
    isPending: boolean = false;

    validationErrors: ValidationError[] | null = null;

    get currentHotelId() {
        return +this.$route.params.hotelId || this.userService.currentHotelId!;
    }

    get canBeIgnored() {
        // NOTE: or cars
        // FIXME events should be ignoreable for cluster and chain as well
        const isHotel = !['cluster', 'chain'].includes(this.userService.viewAs!);

        return isHotel && !this.isMyEvent;
    }

    get isOwnerCurrentHotel() {
        if (this.event) {
            return this.event.entityId === this.currentHotelId;
        }
        return false;
    }

    get isApproved() {
        if (!this.event) return false;
        return this.event.group === EventGroup.MAIN;
    }

    get isHoliday() {
        if (!this.event) return false;
        return this.event.group === EventGroup.HOLIDAY;
    }

    get isSuggested() {
        if (!this.event) return false;
        return this.event.group === EventGroup.SUGGESTED;
    }

    get isMyEvent(): boolean {
        const { event } = this;

        if (!event || !this.userService.user) {
            return false;
        }

        const isCreator = event.creatorId === this.userService.user.id;

        if (isCreator) {
            return true;
        }

        const isChainUser = ['cluster', 'chain'].includes(this.userService.userLevel);
        const isTypeHotel = event.entityType === 'hotel';

        if (isChainUser && isTypeHotel) {
            return this.userService.user.hotelIds.includes(Number(event.entityId));
        }

        return false;
    }

    get event() {
        if (this.eventId) {
            return this.eventsManagerService.getEventById(this.eventId);
        }

        return null;
    }

    formatDate(date: string) {
        const d = new Date(date);
        const dayName = d.toLocaleDateString('en-US', { weekday: 'short', timeZone: 'UTC' });
        const day = (`0${d.getUTCDate()}`).slice(-2);
        const month = (`0${d.getUTCMonth() + 1}`).slice(-2);
        return `${dayName} ${day}/${month}/${d.getUTCFullYear()}`;
    }

    editEvent() {
        this.$router.push({
            name: this.$route.name!.replace('view', 'edit'),
            params: {
                ...this.$route.params,
                eventId: String(this.eventId),
            },
        });
    }

    async approve() {
        if (!this.eventId) {
            return;
        }

        const errors = await this.eventsManagerService.approveEvent(this.eventId);

        if (errors.length) {
            this.validationErrors = [...errors];
            return;
        }

        this.close();
    }

    toggleDeleteEventPopup(): void {
        this.isDeleting = !this.isDeleting;
    }

    toggleIgnoreEventPopup(): void {
        this.isIgnoring = !this.isIgnoring;
    }

    ignoreEvent() {
        this.isPending = true;

        this.eventsManagerService
            .ignoreEvent(this.eventId!)
            .finally(() => {
                this.isPending = false;
                this.isIgnoring = false;
                this.close();
            });
    }

    deleteEvent() {
        this.isPending = true;

        this.eventsManagerService
            .removeEvent(this.eventId!)
            .finally(() => {
                this.isPending = false;
                this.isDeleting = false;
                this.close();
            });
    }

    close() {
        this.$emit('close');
    }
}
