
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import EventTypeIcon from '@/modules/common/components/ui-kit/event-type-icon.vue';
import Flag from '@/modules/common/components/ui-kit/flag.vue';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import EventGroup from '@/modules/events/interfaces/event-group.enum';

@Component({
    components: {
        Flag,
        EventTypeIcon,
    },
})

export default class ViewEventTitle extends Vue {
    @Inject(EventsManagerServiceS)
    private eventsManagerService!: EventsManagerService;

    @Prop({ required: false })
    private eventId?: string;

    get event() {
        if (this.eventId) {
            return this.eventsManagerService.getEventById(this.eventId);
        }
        return null;
    }

    get isHoliday() {
        if (!this.event) return null;

        return this.event?.group === EventGroup.SUGGESTED;
    }
}
